import React from 'react'
import Logo from "../assets/logo.png";

const Footer = () => {
  return (
    <div className="w-full h-15 bg-[#5d1af8dc]">
        <div className="flex justify-center">
        <a href="/">
          <img src={Logo} alt="Logo Image" style={{ width: "70px" }} />
        </a>
      </div>
      </div>
  )
}

export default Footer