import React from "react";
import Example1 from "../assets/henchhsnapshot.png";
import Pilodev from "../assets/pilodevsnapshot.png";
import CompExample from "../assets/contactformscreenshot.png";
import Skills from "../components/Skills";
import { Link } from 'react-scroll';

const Work = () => {
  return (
    <div
      name="work"
      className="w-full md:h-screen text-gray-300 text-center bg-[#6a0dff]"
    >
      {/* Container */}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-50%">
        <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-50%">
          {/* Container */}
          <div classname="my-8">
          <h1 className="text-4xl sm:text-md font-bold text-[#ccd6f6]">
            <br />
            <br />
          We work with a wide range of technologies to
          </h1>
          <h2 className="text-3xl sm:text-md font-bold text-[#9aa5c5]">
          create a personalized solution for you!
          </h2>
            <h1 className="text-lg text-[#ccd6f6]">
              <br />
              // Check out some of our recent work
              <br />
              <br />
            </h1>
          </div>
          <div className="grid sm:grid-cols-2 md:grid-cols-2 gap-4">
            {/* Grid Item section*/}
            <div
              style={{ backgroundImage: `url(${Example1})` }}
              className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
            >
              {/* Hover Effects */}
              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-2xl font-bold text-white tracking-wider">
                  Personalized web App
                </span>
                <div className="pt-8 text-center"></div>
              </div>
            </div>
            <div
              style={{ backgroundImage: `url(${Pilodev})` }}
              className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div"
            >
              {/* Hover Effects */}
              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-2xl font-bold text-white tracking-wider">
                  Animated Portfolio
                </span>
                <div className="pt-8 text-center"></div>
              </div>
            </div>
            {/* Grid Item section*/}
          </div>
        </div>
        <p>
          <br />
          <br />
          <br />
          <br />
          <br />
        </p>
        
        <p>
          <br />
          <br />
        </p>
      </div>
    </div>
  );
};

export default Work;
