import React, { useState } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";
import Logo from "../assets/logo.png";
import HomeFoot from "../components/HomeFoot";

const Home = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <>
      <div name="home" className="w-full h-screen bg-[#6a0dffd0]">
        {/* Container */}
        <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
          <h1 className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
            We make the greatest websites
          </h1>
          <h2 className="text-4xl sm:text-7xl font-bold text-[#8892b0]">
            Just ask my mom..
          </h2>
          <div>
            <button className="text-white text-2xl group border-2 px-2 py-1 my-4 flex items-center hover:bg-pink-600 hover:border-pink-600 rounded-md">
              <Link to="about" smooth={true} duration={500}>What?</Link>
              <span className="group-hover:rotate-90 duration-700">
                <HiArrowNarrowRight className="ml-3 " />
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
