import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../assets/logo.png";
import { Link } from 'react-scroll';


const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#7615f59a] text-gray-300">
      <div>
        <a href="/">
          <img src={Logo} alt="Logo Image" style={{ width: "120px" }} />
        </a>
      </div>

      {/* menu */}
      <ul className="hidden md:flex">
        <li>
        <Link to='home' smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li>
        <Link to='work' smooth={true} duration={500}>
            Portfolio
          </Link>
        </li>
        <li>
        <Link to='about' smooth={true} duration={500}>
            About Us
          </Link>
        </li>
        <li>
        <Link to='contact' smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className="md:hidden z-10">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-[#d665c7] flex flex-col justify-center items-center"
        }
      >
        <li className="py-6 text-4xl">
        <Link onClick={handleClick} to='home' smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
        <Link onClick={handleClick} to='work' smooth={true} duration={500}>
            Portfolio
          </Link>
        </li>
        <li className="py-6 text-4xl">
        <Link onClick={handleClick} to='about' smooth={true} duration={500}>
            About Us
          </Link>
        </li>
        <li className="py-6 text-4xl">
        <Link onClick={handleClick} to='contact' smooth={true} duration={500}>
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
