import React, { useState } from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from 'react-scroll';

const HomeFoot = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <>
      <div name="homefoot" className="w-full h-screen bg-[#6a0dff]">
        {/* Container */}
        <div className="max-w-[1000px] max-h-[900px] mx-auto px-8 flex flex-col justify-center h-full">
          <p className="text-[#d3e43c] font-bold text">
          <br />
          <br />
          <br />
          <br />
            We do some pretty cool sh*t
          </p>
          <h1 className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
            HENCHH
          </h1>
          <h2 className="text-4xl sm:text-7xl font-bold text-[#8892b0]">
            Design and Development
          </h2>
          <p className="text-[#ffffff] py-4 max-w-[700px]">
            //Professional & responsive apps at your finger tips
            <br /> <br />
          </p>
          <p className="text-[#ffffff] py-4 max-w-[700px]">
            We're not just any dev studio, we're more like a solution company,
            here to help you! From creating the perfect website you deserve, to
            SEO and security consultations. Our dedicated team will work with
            you to find just what you need, from personal projects to
            professional needs.
          </p>
          <div>
            <button className="text-white group border-2 px-4 py-4 my-8 flex items-center hover:bg-pink-600 hover:border-pink-600 rounded-md">
            <Link to='work' smooth={true} duration={500}>
            What we do
            </Link>

              <span className="group-hover:rotate-180 duration-700">
                <HiArrowNarrowRight className="ml-2" />
              </span>
            </button>
          </div>
          <p>
            <br />
            <br />

          </p>
        </div>
      </div>
    </>
  );
};

export default HomeFoot;
